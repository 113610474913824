import React, { useState } from "react"
import { navigate } from "gatsby"
import { Formik, Form, Field, ErrorMessage } from "formik"

import Message from "../../../elements/Message"

import {
  PatientRequiredPersonalDetails,
  PatientOtherPersonalDetails,
  PatientDefaultValues,
  PatientDoctor,
  PatientPrescriptionDetails,
  PatientSecondaryContact,
  planYourFirstOrder,
} from "./patientInitializers"
import { patientValidationSchema } from "../helpers"
import { sendPatientEnrollmentForm } from "../../../../utils/enrollmentFormService"
import { handleMaxLength } from "../form-utils"

import FormTemplate from "../../form-template"
import FormsSection from "../forms-section"
import ConsentAndAuthorization from "../consent-and-authorization"
import ProgramTransfer from "./program-transfer"
import DateInput from "../../date-input"
import FieldContainer from "../field-container"
import Address from "../../Address/Address"
import styles from "../enrollment-form.module.scss"
import { enrollmentTextMessageTemplate } from "../templates/enrollmentTextTemplate"

const PxEnrollmentForm = () => {
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [dropzoneFiles, setDropzoneFiles] = useState([])

  const addressFieldValues = {
    addressType: "addressType",
    streetAddress: "streetAddress",
    province: "province",
    city: "city",
  }

  const callback = resetForm => () => {
    setIsLoading(false)
    resetForm()
    navigate("/confirmation")
  }

  const handleErrorSubmit = ({ message }) => {
    setIsLoading(false)
    setErrorMessage(message)
  }

  const handleSubmit = (values, { resetForm }) => {
    setIsLoading(true)
    sendPatientEnrollmentForm(
      values,
      dropzoneFiles,
      callback(resetForm),
      enrollmentTextMessageTemplate,
      handleErrorSubmit
    )
  }

  const handleMapDropzoneState = tempDropzoneFiles => {
    setDropzoneFiles(tempDropzoneFiles)
  }

  const RenderPatientPrescriptionDetails = ({
    errors,
    touched,
    values,
    handleChange,
  }) =>
    PatientPrescriptionDetails.map((field, index) => {
      return (
        <DateInput
          key={index}
          label={field.label}
          name={field.name}
          helper={field.helper || null}
          isRequired={field.isRequired}
          errors={errors}
          values={values}
          touched={touched}
          handleChange={handleChange}
          handleMaxLength={handleMaxLength}
        />
      )
    })

  return (
    <FormTemplate title="Patient Enrollment Form">
      <Formik
        initialValues={PatientDefaultValues}
        onSubmit={handleSubmit}
        validationSchema={patientValidationSchema}
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => {
          return (
            <Form autoComplete="off">
              <FormsSection
                fields={PatientRequiredPersonalDetails}
                values={values}
                sectionTitle="Patient Details"
                onChangeInput={handleMaxLength}
                handleChange={handleChange}
              />
              <DateInput
                label="Birthday"
                name="birth"
                errors={errors}
                values={values}
                touched={touched}
                handleChange={handleChange}
                handleMaxLength={handleMaxLength}
                isRequired
              />
              <FormsSection
                fields={PatientOtherPersonalDetails}
                values={values}
                onChangeInput={handleMaxLength}
                handleChange={handleChange}
              />
              <ProgramTransfer
                dropzoneFiles={dropzoneFiles}
                handleMapDropzoneState={handleMapDropzoneState}
                values={values}
              />
              <FieldContainer
                label="You will likely order within:"
                helper="To serve you better, please let us know when you will likely order."
                isRequired
              >
                {planYourFirstOrder.map(option => (
                  <div key={option.value}>
                    <label>
                      <Field
                        type="radio"
                        className={styles.radio}
                        name="planYourFirstOrder"
                        value={option.value}
                      />
                      <span className="ml-1">{option.label}</span>
                    </label>
                  </div>
                ))}
                <div className="has-text-danger is-size-7">
                  <ErrorMessage name="planYourFirstOrder" />
                </div>
              </FieldContainer>
              <FieldContainer label="Address Details" isRequired>
                <Address
                  values={values}
                  isRequired
                  setFieldValue={setFieldValue}
                  isNationwide
                  withAddressType
                  fieldNames={addressFieldValues}
                  isRequired
                />
              </FieldContainer>

              <FormsSection
                values={values}
                fields={PatientSecondaryContact}
                sectionTitle="Secondary Contact Person"
                subtitle="Please provide alternate contact person in case you cannot be reached through your primary contact avenues."
                onChangeInput={handleMaxLength}
                handleChange={handleChange}
              />
              <FormsSection
                values={values}
                fields={PatientDoctor}
                sectionTitle="Doctor"
                subtitle="Please ensure that this is the registered name of your doctor in the Pulse Care Program."
                handleChange={handleChange}
              />
              <h4 className="has-text-weight-bold is-size-5 mt-2">
                Prescription Details
              </h4>
              <RenderPatientPrescriptionDetails
                errors={errors}
                touched={touched}
                values={values}
                handleChange={handleChange}
                handleMaxLength={handleMaxLength}
              />

              <ConsentAndAuthorization
                values={values}
                setFieldValue={setFieldValue}
                isPatient
              />
              {errorMessage && <Message color="danger">{errorMessage}</Message>}
              <button
                className={`button is-primary ${
                  isLoading ? "is-loading" : " "
                }`}
                type="submit"
              >
                Submit Enrollment Form
              </button>
            </Form>
          )
        }}
      </Formik>
    </FormTemplate>
  )
}

export default PxEnrollmentForm
