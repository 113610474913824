export const PatientDefaultValues = {
  lastName: "",
  firstName: "",
  middleName: "",
  birthday: "",
  birthMonth: "",
  birthDate: "",
  birthYear: "",
  mobileNumber: "",
  emailAddress: "",
  discountAvailed: "none",
  isTransferringPatient: "",
  experienceAdverseEvents: "",
  heartCarePatientCode: "",
  planYourFirstOrder: "",
  streetAddress: "",
  city: { value: "" },
  province: { value: "" },
  addressType: "",
  secondaryContact: {
    name: "",
    mobileNumber: "",
  },
  doctor: "",
  prescriptionDetails: {
    latestRxDate: "",
    lastCheckupDate: "",
    lastConfinementDate: "",
  },
  consentAndAuthorization: [],
}

export const PatientRequiredPersonalDetails = [
  {
    type: "text",
    label: "Last Name",
    name: "lastName",
    isRequired: true,
    placeholder: "dela Cruz",
  },
  {
    type: "text",
    label: "First Name",
    name: "firstName",
    isRequired: true,
    placeholder: "Juan",
  },
  {
    type: "text",
    label: "Middle Name",
    name: "middleName",
    isRequired: true,
    placeholder: "Reyes",
  },
  {
    type: "text",
    label: "Mobile Number",
    name: "mobileNumber",
    isRequired: true,
    helper:
      "Please ensure that your mobile number is correct as your enrollment details will be sent here.",
    placeholder: "09991234567",
    maxLength: "11",
    isNumeric: true,
  },
]

export const PatientOtherPersonalDetails = [
  {
    type: "text",
    label: "Alternative Landline",
    name: "alternativeClinicLandlineNumber",
    maxLength: 11,
    isNumeric: true,
    isRequired: false,
    placeholder: "(02) 8544-1234",
  },
  {
    type: "email",
    label: "Email Address",
    name: "emailAddress",
    isRequired: false,
    helper:
      "If filled out, please ensure that your email address is correct as your enrollment details will be sent here.",
    placeholder: "jdelacruz@email.com",
  },
]

export const PatientDoctor = [
  {
    type: "text",
    label: "Full Name",
    name: "doctor",
    isRequired: true,
    placeholder: "Dr James Santos",
  },
]

export const PatientPrescriptionDetails = [
  {
    type: "date",
    label: "Date of Latest Prescription",
    name: "latestRxDate",
    isRequired: true,
    helper:
      "Please input the date you received your latest prescription for Sacubitril-Valsartan. This is the prescription you will be submitting when you order Sacubitril-Valsartan from pulsecare.medgrocer.com.",
  },
  {
    type: "date",
    label: "Date of Last Check-up",
    name: "lastCheckupDate",
    isRequired: false,
    helper: "Please input the date you last saw your doctor for a check-up.",
  },
]

export const PatientSecondaryContact = [
  {
    type: "text",
    label: "Full Name",
    name: "secondaryContact.name",
    isRequired: true,
  },
  {
    type: "text",
    label: "Mobile Number",
    name: "secondaryContact.mobileNumber",
    isRequired: true,
    maxLength: 11,
    isNumeric: true,
  },
]

export const isTransferringPatientChoices = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
]

export const experienceAdverseEvents = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
]
export const planYourFirstOrder = [
  { label: "The week", value: "The week" },
  { label: "The month", value: "The month" },
  { label: "I do not know", value: "I do not know" },
]
