import React, { Fragment } from "react"
import { Field, ErrorMessage } from "formik"

import FormInput from "../../FormInput"
import FieldContainer from "../field-container"
import Dropzone from "../../../dropzone/dropzone"

import styles from "../enrollment-form.module.scss"
import {
  isTransferringPatientChoices,
  experienceAdverseEvents,
} from "./patientInitializers"

const ProgramTransfer = ({ dropzoneFiles, handleMapDropzoneState, values }) => (
  <Fragment>
    <FieldContainer
      label="Are you a patient transferring from the Heart Care Program?"
      isRequired
    >
      {isTransferringPatientChoices.map(option => (
        <div key={option.value}>
          <label>
            <Field
              type="radio"
              className={styles.radio}
              name="isTransferringPatient"
              value={option.value}
            />
            <span className="ml-1">{option.label}</span>
          </label>
        </div>
      ))}
      <div className="has-text-danger is-size-7">
        <ErrorMessage name="isTransferringPatient" />
      </div>
    </FieldContainer>
    {values.isTransferringPatient === "Yes" && (
      <Fragment>
        <FieldContainer
          label="Have you reported any adverse events?"
          isRequired
        >
          {experienceAdverseEvents.map(option => (
            <div>
              <label>
                <Field
                  type="radio"
                  className={styles.radio}
                  name="experienceAdverseEvents"
                  value={option.value}
                />
                <span className="ml-1">{option.label}</span>
              </label>
            </div>
          ))}

          <div className="has-text-danger is-size-7">
            <ErrorMessage name="experienceAdverseEvents" />
          </div>
        </FieldContainer>
        <div className="mt-2">
          <FormInput
            label="Heart Care Program Serial Code"
            helper="You may contact your previous provider to receive your HCP code."
            name={`heartCarePatientCode`}
            patternRegex={/[\W_]+/g}
          />
        </div>
        {/* <FieldContainer
          label="Heart Care Program Serial Code"
          helper="You may contact your previous provider to receive your HCP code."
        >
          <Field className="input" name="heartCarePatientCode" maxLength="50" />
          <div className="has-text-danger is-size-7">
            <ErrorMessage name="heartCarePatientCode" />
          </div>
        </FieldContainer> */}
      </Fragment>
    )}
  </Fragment>
)

export default ProgramTransfer
