import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
// import EnrollmentForm from '../components/forms/enrollment-form/enrollment-form'
import PxEnrollmentForm from '../components/forms/enrollment-form/patient/px-enrollment-form'

const PatientEnrollmentForm = () => {
  return (
    <Layout>
      <SEO
        title="Enroll as a PCP Patient"
        keywords={[
          `novartis`,
          `medgrocer`,
          `pulse care`,
          `entresto`,
          `sacubitril-valsartan`,
        ]}
      />
      {/* <EnrollmentForm isPatient /> */}
      <PxEnrollmentForm />
    </Layout>
  )
}

export default PatientEnrollmentForm
